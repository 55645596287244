<script setup lang="ts">
</script>

<template>
  <section class="max-w-[1028px] flex flex-col gap-[32px] px-[20px] lg:px-0 container-body"
           data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <div class="flex flex-col gap-[24px]">
      <h1 class="mobile-heading-1 text-color-gray-50 md:desktop-heading-1">Políticas de Privacidad: Uso de NexUcss</h1>
      <p class="mobile-body md:desktop-body text-color-gray-500">Estos Términos y Condiciones regulan el uso del aplicativo de control de docentes (en adelante, el "Aplicativo") desarrollado para la Universidad Católica Sedes Sapientiae (en adelante, la "Universidad"). Al acceder o utilizar el Aplicativo, aceptas cumplir y estar sujeto a estos Términos y Condiciones. Si no aceptas estos términos, no debes utilizar el Aplicativo.</p>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">1. Objeto del Aplicativo</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>El Aplicativo tiene como finalidad gestionar y controlar la asistencia de los docentes en la Universidad Católica Sedes Sapientiae, permitiendo al personal autorizado el registro, monitoreo y gestión de los horarios y asistencia de los docentes.</p>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">2. Usuarios Autorizados</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>El acceso y uso del Aplicativo está estrictamente limitado al personal responsable del monitoreo de horarios y asistencias designado por la Universidad. No se permitirá el acceso a otros usuarios ni a docentes.</p>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">3. Recopilación de Información</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>El Aplicativo recopila datos relacionados únicamente con la asistencia de los docentes, los cuales no serán cargados a ninguna base de datos externa. Todos los datos se almacenarán localmente en el dispositivo del usuario autorizado.</p>
        <ul class="list-disc pl-[50px]">
          <li>Nombres completos del docente</li>
          <li>Registro de entrada, monitoreo y salida</li>
          <li>Horarios asignados</li>
          <li>Nombres completos de los responsables del monitoreo</li>
          <li>Nombre completos y Datos de contacto de los coordinadores de cada carrera</li>
        </ul>
        <p>El personal autorizado es responsable de asegurar la precisión de los datos ingresados y el correcto uso de la plataforma. Además queda estrictamente prohibido que los usuarios compartan, distribuyan o transfieran estos datos a terceros. El incumplimiento de esta política será motivo de sanciones por parte de la Universidad Católica Sedes Sapientiae.</p>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">4. Responsabilidades del Personal Autorizado</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <ul class="list-disc pl-[50px]">
          <li>Utilizar el Aplicativo exclusivamente para el monitoreo de horarios y asistencia de los docentes.</li>
          <li>No compartir sus credenciales de acceso (usuario y contraseña) con ninguna otra persona.</li>
          <li>Reportar cualquier incidente de seguridad o uso indebido del Aplicativo.</li>
          <li>Mantener la confidencialidad de los datos del personal docente monitoreado.</li>
        </ul>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">5. Propiedad Intelectual</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>El Aplicativo, incluyendo su diseño y código, es propiedad exclusiva de los desarrolladores José Jarlin Chiquin, Jhordy Mondragón y William Calderón. Todos los derechos sobre el software y su estructura técnica están reservados a ellos. El contenido ingresado al Aplicativo por el personal autorizado, así como los reportes generados, serán propiedad de la Universidad Católica Sedes Sapientiae. Sin embargo, estos datos no serán almacenados en ninguna base de datos externa y permanecerán localmente en el dispositivo del usuario autorizado. La distribución o el uso no autorizado de esta información está prohibido.</p>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">6. Limitaciones de Uso</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>El uso del Aplicativo se limita exclusivamente al personal autorizado para realizar el monitoreo de horarios y asistencia de los docentes. Queda prohibido utilizar el Aplicativo para cualquier otro propósito ajeno a su función principal.</p>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">7. Modificaciones a los Términos y Condiciones</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>Cualquier modificación a los términos relacionados con la recopilación, almacenamiento y manejo de datos del Aplicativo podrá ser realizada únicamente por los desarrolladores José Jarlin Chiquin, Jhordy Mondragón y William Calderón. La Universidad Católica Sedes Sapientiae podrá proponer cambios o ajustes a estos términos, pero dichos cambios estarán sujetos a una evaluación y aprobación previa por parte de los desarrolladores. Cualquier actualización será notificada a los usuarios autorizados, y el uso continuo del Aplicativo después de las modificaciones implicará la aceptación de los nuevos términos.</p>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">8. Exclusión de Responsabilidad</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>Los desarrolladores José Jarlin Chiquin, Jhordy Mondragón y William Calderón no garantizan que el Aplicativo funcione de manera ininterrumpida o libre de errores. El Aplicativo se proporciona "tal cual", sin garantías explícitas o implícitas de su funcionamiento o adecuación para un propósito específico. Los desarrolladores no serán responsables por ningún tipo de daño, directo o indirecto, que pueda derivarse del uso o la imposibilidad de usar el Aplicativo, incluyendo, pero no limitado a, pérdida de datos, interrupciones del servicio, problemas técnicos o cualquier otro perjuicio que pudiera afectar a los usuarios o a la Universidad Católica Sedes Sapientiae. El uso del Aplicativo es bajo la propia responsabilidad del usuario, y cualquier incidencia deberá ser reportada para su revisión y corrección, sin que ello implique responsabilidad legal para los desarrolladores.</p>
      </div>
    </div>

    <div class="flex flex-col gap-[24px]">
      <h2 class="mobile-heading-2 md:desktop-heading-2 text-color-gray-50">9. Contacto</h2>
      <div class="mobile-body md:desktop-body text-color-gray-500">
        <p>Para cualquier duda, consulta o incidencia relacionada con el funcionamiento del Aplicativo, el personal autorizado de la Universidad deberá ponerse en contacto exclusivamente con los desarrolladores a través de los siguientes correos electrónicos:</p>
        <ul class="list-disc pl-[50px]">
          <li>iCe-Digital: sopport@icedigital.pe</li>
          <li>William Calderón: william@email.com</li>
        </ul>
        <p>El equipo de administración de la Universidad Católica Sedes Sapientiae podrá contactar a los desarrolladores para proponer cambios o resolver dudas técnicas relacionadas con el uso del Aplicativo. Cualquier comunicación relacionada con el manejo de datos o ajustes en el sistema deberá pasar por los desarrolladores para su evaluación y aprobación.</p>
      </div>
    </div>

    <p class="mobile-body md:desktop-body text-color-gray-500">Fecha de Vigencia: 29/09/2024</p>
  </section>
</template>

<style scoped>
/* Añadir aquí estilos como se muestra en el código proporcionado */
</style>
