import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative bg-color-bg-1" }
const _hoisted_2 = { class: "flex flex-col items-center pt-[56px] gap-[100px] md:gap-[130px] z-10 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NexUcss = _resolveComponent("NexUcss")!
  const _component_UcssHub = _resolveComponent("UcssHub")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      (_ctx.isNexUcssRoute)
        ? (_openBlock(), _createBlock(_component_NexUcss, { key: 0 }))
        : (_ctx.isUcssHubRoute)
          ? (_openBlock(), _createBlock(_component_UcssHub, { key: 1 }))
          : _createCommentVNode("", true)
    ])
  ]))
}